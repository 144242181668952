import React, { useCallback } from 'react';
import {
  Datagrid,
  Edit,
  Create,
  List,
  Show,
  SimpleShowLayout,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  EmailField,
  BooleanField,
  BooleanInput,
  required,
  regex,
  FunctionField,
  SelectInput,
  Button,
  useNotify,
  Filter,
  NullableBooleanInput,
  FormDataConsumer,
  useTranslate,
  Toolbar,
  SaveButton,
} from 'react-admin';
import { Field, useForm } from 'react-final-form';
import PublishIcon from '@material-ui/icons/Publish';
import {
  additionalShowProps,
  getRegionRattachement,
  getRoles,
  isDataManager,
  isShowOnlyRoles,
  shouldSeeAllRegionsRoles,
} from 'technical/auth/services';
import logger from 'technical/logger';
import {
  exporter,
  downloadCSVForExcel,
  ReferenceSearchInput,
  PartialSearchInput,
  TranslateField,
  GooglePlacesInput,
  AddressField,
  ListActions,
} from 'components';
import RegionFilter from 'components/RegionFilter';
import md5 from 'md5';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import createUUID from 'components/createUuid';
import PartialSearchInputStartWith from 'components/PartialSearchInputStartWith';
import styles from '../class/index.module.css';
import importFile from '../class/importFile';

const roles = getRoles();

export const types = {
  primary: 'Primaire',
  secondary: 'Collège',
  highschool: 'Lycée',
};

export const categories = {
  rep: 'REP',
  'rep+': 'REP +',
  'no rep': 'Non REP',
};

export const academies = {
  'AIX-MARSEILLE': 'AIX-MARSEILLE',
  AMIENS: 'AMIENS',
  BESANCON: 'BESANCON',
  BORDEAUX: 'BORDEAUX',
  'CLERMONT-FERRAND': 'CLERMONT-FERRAND',
  CORSE: 'CORSE',
  CRETEIL: 'CRETEIL',
  DIJON: 'DIJON',
  GRENOBLE: 'GRENOBLE',
  GUADELOUPE: 'GUADELOUPE',
  GUYANE: 'GUYANE',
  'LA REUNION': 'LA REUNION',
  LILLE: 'LILLE',
  LIMOGES: 'LIMOGES',
  LYON: 'LYON',
  MARTINIQUE: 'MARTINIQUE',
  MAYOTTE: 'MAYOTTE',
  MONTPELLIER: 'MONTPELLIER',
  'NANCY-METZ': 'NANCY-METZ',
  NANTES: 'NANTES',
  NICE: 'NICE',
  NORMANDIE: 'NORMANDIE',
  'ORLEANS-TOURS': 'ORLEANS-TOURS',
  PARIS: 'PARIS',
  POITIERS: 'POITIERS',
  REIMS: 'REIMS',
  RENNES: 'RENNES',
  STRASBOURG: 'STRASBOURG',
  TOULOUSE: 'TOULOUSE',
  VERSAILLES: 'VERSAILLES',
};

const choices = (values: Object) => {
  const list = Object.entries(values).reduce(
    (agg, [key, value]) => [...agg, { id: key, name: value }],
    [] as { id: string; name: string }[],
  );
  return list;
};

const ImportButton = props => {
  const { record } = props;
  const notify = useNotify();
  const onChange = async e => {
    try {
      await Promise.all(
        ([...e.target.files] as File[]).map(file =>
          importFile(file, record, notify),
        ),
      );
      notify('resources.establishment.success.importPack', 'success');
    } catch (err) {
      logger.error(err.message);
    }
  };

  return (
    <Button
      className={styles.importContainer}
      label="resources.establishment.actions.importPack"
      onClick={e => e.stopPropagation()}
    >
      <>
        <input className={styles.importInput} onChange={onChange} type="file" />
        <PublishIcon />
      </>
    </Button>
  );
};

const optionRenderer = contact =>
  contact?.firstName || contact?.lastName || contact?.email
    ? `${contact.firstName} ${contact.lastName} - ${contact.email}`
    : 'Aucun';

const filterToQuery = searchText => ({
  _and: [
    { regionId: { _eq: getRegionRattachement() } },
    {
      _or: [
        { firstName: { _ilike: `%${searchText}%` } },
        { lastName: { _ilike: `%${searchText}%` } },
        { email: { _ilike: `%${searchText}%` } },
      ],
    },
  ],
});

const EstablishmentFilters = props => (
  <Filter {...props}>
    <PartialSearchInput source="name" />
    {/* <PartialSearchInput source="UAICode" /> */}
    <PartialSearchInput source="city" />
    <PartialSearchInput source="referenceAcademy" />
    <PartialSearchInput source="addressSecondary" />
    <SelectInput choices={choices(types)} source="type" />
    <NullableBooleanInput source="isActive" alwaysOn />
    <ReferenceSearchInput
      source="principalId"
      reference="contact"
      filterToQuery={filterToQuery}
      optionText={optionRenderer}
    />
    <ReferenceSearchInput
      source="principalAssistantId"
      reference="contact"
      filterToQuery={filterToQuery}
      optionText={optionRenderer}
    />
    <ReferenceSearchInput
      source="principalSegpaId"
      reference="contact"
      filterToQuery={filterToQuery}
      optionText={optionRenderer}
    />
    <ReferenceSearchInput
      source="educationAdvisorId"
      reference="contact"
      filterToQuery={filterToQuery}
      optionText={optionRenderer}
    />
    <ReferenceSearchInput
      source="collegeReferentId"
      reference="contact"
      filterToQuery={filterToQuery}
      optionText={optionRenderer}
    />
    <PartialSearchInputStartWith source="postalCode" />
    {isDataManager(roles) && <RegionFilter label="Région" source="regionId" />}
  </Filter>
);

const EstablishmentExpand = props => {
  const { record, ...rest } = props;

  return (
    <Show {...rest} title=" ">
      <SimpleShowLayout>
        <TranslateField namespace="establishment.types" source="type" />
        <BooleanField source="isEducationalCity" />
        <ReferenceField source="principalId" reference="contact">
          <FunctionField
            label="Name"
            render={(principal: any) =>
              `${principal.firstName} ${principal.lastName}`
            }
          />
        </ReferenceField>
        <ReferenceField source="principalAssistantId" reference="contact">
          <FunctionField
            label="Name"
            render={(assistant: any) =>
              `${assistant.firstName} ${assistant.lastName}`
            }
          />
        </ReferenceField>
        <ReferenceField source="educationAdvisorId" reference="contact">
          <FunctionField
            label="Name"
            render={(advisor: any) =>
              `${advisor.firstName} ${advisor.lastName}`
            }
          />
        </ReferenceField>
        <ReferenceField source="principalSegpaId" reference="contact">
          <FunctionField
            label="Name"
            render={(principalSagpa: any) =>
              `${principalSagpa.firstName} ${principalSagpa.lastName}`
            }
          />
        </ReferenceField>
        <ReferenceField source="collegeReferentId" reference="contact">
          <FunctionField
            label="Name"
            render={(principalSagpa: any) =>
              `${principalSagpa.firstName} ${principalSagpa.lastName}`
            }
          />
        </ReferenceField>
      </SimpleShowLayout>
    </Show>
  );
};

export const EstablishmentList = (props: any) => {
  return (
    <List
      {...props}
      filters={<EstablishmentFilters />}
      filterDefaultValues={{
        isActive: true,
      }}
      sort={{ field: 'name', order: 'ASC' }}
      {...(isShowOnlyRoles(roles) ? { bulkActionButtons: false } : {})}
      actions={<ListActions />}
      exporter={records =>
        exporter(records).then(csv =>
          downloadCSVForExcel(csv, 'etablissements'),
        )
      }
    >
      <Datagrid
        rowClick={isShowOnlyRoles(roles) ? 'show' : 'edit'}
        expand={<EstablishmentExpand />}
      >
        <TextField source="name" />
        <BooleanField source="isMfrOrRelay" />
        <TextField source="UAICode" />
        <AddressField source="address" />
        <TextField source="addressSecondary" />
        <TextField source="phone" />
        <EmailField source="email" />
        <TranslateField
          namespace="establishment.categories"
          source="category"
        />
        <ReferenceField source="regionId" reference="region">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="referenceAcademy" />
        <BooleanField source="isActive" />
        {!isShowOnlyRoles(roles) && <ImportButton />}
      </Datagrid>
    </List>
  );
};

const validateUAICode = [
  required(),
  regex(
    /^\d{7}[A-Z]{1}$/,
    'Le code UAI doit comporter 7 chiffres et une lettre majuscule.',
  ),
];
const createUAICode = uuid => {
  const numberRandom = parseInt(md5(uuid), 16);
  const fourDigits = numberRandom % 9999;
  const letter = numberRandom % 25;
  return '999'
    .concat(fourDigits.toString(10))
    .concat(String.fromCharCode(letter + 97).toUpperCase());
};

const useStyles = makeStyles({
  root: {
    display: 'none',
  },
  width: {
    width: '260px',
  },
  disabled: {
    pointerEvents: 'none',
  },
});

const MFRInput = props => {
  const form = useForm();
  const { uuid, UAICode } = props;
  const translate = useTranslate();
  return (
    <BooleanInput
      source="isMfrOrRelay"
      defaultValue={false}
      label={translate('resources.establishment.fields.isMfrOrRelay')}
      onChange={value => {
        if (value !== false) {
          form.change('UAICode', UAICode);
          form.change('id', uuid);
        }
      }}
    />
  );
};

const EstablishmentForm = props => {
  const classes = useStyles();
  const { id } = props;
  const { record } = props;
  const uuid = id ?? createUUID();
  const UAICode = record?.UAICode ?? createUAICode(uuid);
  return (
    <SimpleForm redirect="list" toolbar={<PostSubmitToolbar />} {...props}>
      <BooleanInput source="isActive" />
      <MFRInput {...props} uuid={uuid} UAICode={UAICode} />
      <FormDataConsumer>
        {/* className is set to avoid RA from overriding our style */}
        {({ formData, className, ...rest }) => {
          if (formData.isMfrOrRelay) {
            return (
              <>
                <Typography>
                  Votre établissement n&apos;ayant pas de code UAI, nous
                  enregistrons le code suivant pour traitement statistique.:{' '}
                  <br />
                </Typography>
                <Typography variant="subtitle1">{UAICode}</Typography>

                <TextInput
                  source="UAICode"
                  value={UAICode}
                  className={classes.root}
                  {...rest}
                />

                <TextInput source="id" className={classes.root} />
              </>
            );
          }
          return (
            <>
              <Typography>
                Vous pouvez retrouver le code UAI de votre établissement
                sur&nbsp;
                <a
                  href="https://www.education.gouv.fr/acce_public/search.php"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  https://www.education.gouv.fr/acce_public/search.php
                </a>
              </Typography>

              <TextInput
                source="UAICode"
                validate={validateUAICode}
                className={classes.width}
                {...rest}
              />
            </>
          );
        }}
      </FormDataConsumer>
      <TextInput source="name" validate={required()} />
      <Field name="algolia" component={GooglePlacesInput} />
      <TextInput source="address" disabled validate={required()} />
      <TextInput source="postalCode" validate={required()} />
      <TextInput source="city" disabled validate={required()} />
      <TextInput
        source="location"
        validate={required()}
        style={{ display: 'none' }}
      />
      <TextInput source="addressSecondary" />
      <TextInput source="email" validate={required()} type="email" />
      <TextInput source="phone" validate={required()} />
      <AutocompleteInput
        source="referenceAcademy"
        validate={required()}
        choices={choices(academies)}
      />
      <SelectInput
        choices={choices(categories)}
        source="category"
        validate={required()}
      />
      <SelectInput
        choices={choices(types)}
        source="type"
        validate={required()}
      />
      <BooleanInput source="isEducationalCity" />
      <ReferenceInput
        source="principalId"
        reference="contact"
        filterToQuery={filterToQuery}
        allowEmpty
      >
        <AutocompleteInput optionText={optionRenderer} />
      </ReferenceInput>
      <ReferenceInput
        source="principalAssistantId"
        reference="contact"
        filterToQuery={filterToQuery}
        allowEmpty
      >
        <AutocompleteInput optionText={optionRenderer} />
      </ReferenceInput>
      <ReferenceInput
        source="principalSegpaId"
        reference="contact"
        filterToQuery={filterToQuery}
        allowEmpty
      >
        <AutocompleteInput optionText={optionRenderer} />
      </ReferenceInput>
      <ReferenceInput
        source="educationAdvisorId"
        reference="contact"
        filterToQuery={filterToQuery}
        allowEmpty
      >
        <AutocompleteInput optionText={optionRenderer} />
      </ReferenceInput>
      <ReferenceInput
        source="collegeReferentId"
        reference="contact"
        filterToQuery={filterToQuery}
        validate={required()}
      >
        <AutocompleteInput optionText={optionRenderer} />
      </ReferenceInput>
      {shouldSeeAllRegionsRoles(roles) && (
        <ReferenceInput
          source="regionId"
          reference="region"
          filterToQuery={searchText => ({
            name: { _ilike: `%${searchText}%` },
          })}
          validate={[required()]}
        >
          <AutocompleteInput
            optionText="name"
            options={{ autoComplete: 'no' }}
          />
        </ReferenceInput>
      )}
    </SimpleForm>
  );
};

const SaveAndChangeValueButton = ({ handleSubmitWithRedirect }) => {
  const form = useForm();
  const handleClick = useCallback(() => {
    if (form.getFieldState('isMfrOrRelay')?.value === null) {
      form.change('isMfrOrRelay', false);
    }
    form.getFieldState('address-google')?.change(undefined);
    handleSubmitWithRedirect('list');
  }, [form, handleSubmitWithRedirect]);
  return (
    <SaveButton handleSubmitWithRedirect={handleClick} submitOnEnter={false} />
  );
};
const PostSubmitToolbar = props => {
  return (
    <Toolbar {...props}>
      <SaveAndChangeValueButton {...props} />
    </Toolbar>
  );
};

export const EstablishmentEdit = (props: any) => {
  return (
    <Edit {...props}>
      <EstablishmentForm {...props} />
    </Edit>
  );
};

export const EstablishmentCreate = (props: any) => (
  <Create {...props}>
    <EstablishmentForm {...props} />
  </Create>
);

export const EstablihsmentShow = props => (
  <Show {...props} {...additionalShowProps(roles)}>
    <SimpleShowLayout>
      <TextField source="name" />
      <TextField source="UAICode" />
      <AddressField source="address" />
      <TextField source="addressSecondary" />
      <TextField source="phone" />
      <EmailField source="email" />
      <TranslateField namespace="establishment.categories" source="category" />
      <ReferenceField source="regionId" reference="region">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="referenceAcademy" />
      <TranslateField namespace="establishment.types" source="type" />
      <BooleanField source="isEducationalCity" />
      <ReferenceField source="principalId" reference="contact">
        <FunctionField
          label="Name"
          render={(principal: any) =>
            `${principal.firstName} ${principal.lastName}`
          }
        />
      </ReferenceField>
      <ReferenceField source="principalAssistantId" reference="contact">
        <FunctionField
          label="Name"
          render={(assistant: any) =>
            `${assistant.firstName} ${assistant.lastName}`
          }
        />
      </ReferenceField>
      <ReferenceField source="educationAdvisorId" reference="contact">
        <FunctionField
          label="Name"
          render={(advisor: any) => `${advisor.firstName} ${advisor.lastName}`}
        />
      </ReferenceField>
      <ReferenceField source="principalSegpaId" reference="contact">
        <FunctionField
          label="Name"
          render={(principalSagpa: any) =>
            `${principalSagpa.firstName} ${principalSagpa.lastName}`
          }
        />
      </ReferenceField>
      <ReferenceField source="collegeReferentId" reference="contact">
        <FunctionField
          label="Name"
          render={(principalSagpa: any) =>
            `${principalSagpa.firstName} ${principalSagpa.lastName}`
          }
        />
      </ReferenceField>
    </SimpleShowLayout>
  </Show>
);
